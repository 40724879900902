import { NgModule } from '@angular/core';
import {
    ArticleHistoryRowTemplateComponent,
    ArticleMaterialDynamicTemplateComponent,
    ArticleOperationWorkplaceTemplateComponent,
    CalendarListTemplateComponent,
    HolidayRowTemplateComponent,
    NameColorTemplateComponent,
    RoleSpecialisationTableFilterTemplateComponent,
    ToolbarActionTemplateComponent,
    TimeTagRowTemplateComponent,
    WorkgroupWorkplacesTemplateComponent,
    AgentCustomerTemplateComponent,
    ResourcesWorkgroupOperationsTemplateComponent,
    WorkgroupSpecialisationsTemplateComponent,
    MaterialSupplierRowTemplateComponent,
    LoginBlockFilesRowComponent,
    AnalyzedItemsRowComponent
} from './components/frontend-list-manager-dynamic-template.component';
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { InputsModule } from '../inputs/inputs.module';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { FrontendPipesModule } from '../shared/pipes/frontend-pipes.module';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';
import { FrontendDirectivesModule } from '../shared/frontend-directives.module';
import { PromanColorComponent } from '@proman/color/proman-color.component';
import { PromanListManagerModule } from "@proman/list-manager/list-manager.module";
import { PromanExpressionModule } from '@proman/expression/proman-expression.module';
import { PromanAutocompleteComponent } from '@proman/autocomplete/proman-autocomplete.component';
import { PromanTextSimpleComponent } from '@proman/text-simple';
import { PromanThumbnailComponent } from "@proman/common-components/components/proman-thumbnail.component";

const COMPONENTS = [
    HolidayRowTemplateComponent,
    ArticleHistoryRowTemplateComponent,
    NameColorTemplateComponent,
    RoleSpecialisationTableFilterTemplateComponent,
    ArticleMaterialDynamicTemplateComponent,
    ArticleOperationWorkplaceTemplateComponent,
    TimeTagRowTemplateComponent,
    CalendarListTemplateComponent,
    WorkgroupWorkplacesTemplateComponent,
    AgentCustomerTemplateComponent,
    ToolbarActionTemplateComponent,
    ResourcesWorkgroupOperationsTemplateComponent,
    WorkgroupSpecialisationsTemplateComponent,
    LoginBlockFilesRowComponent,
    AnalyzedItemsRowComponent,
];

@NgModule({
    imports: [
        CommonModule,
        DragulaModule.forRoot(),
        MatExpansionModule,
        MatLegacyListModule,
        FlexLayoutModule,
        PromanCommonComponentsModule,
        InputsModule,
        PipesModule,
        FrontendDirectivesModule,
        SharedDirectivesModule,
        PromanExpressionModule,
        RouterModule,
        FrontendPipesModule,
        PromanColorComponent,
        PromanAutocompleteComponent,
        PromanTextSimpleComponent,
        PromanThumbnailComponent,
        PromanListManagerModule.forRoot({
            templates: {
                'holiday_row': HolidayRowTemplateComponent,
                'article_history_row': ArticleHistoryRowTemplateComponent,
                'name_color': NameColorTemplateComponent,
                'role_specialisation_table_filter_template': RoleSpecialisationTableFilterTemplateComponent,
                'article_material': ArticleMaterialDynamicTemplateComponent,
                'article_operation_workplace': ArticleOperationWorkplaceTemplateComponent,
                'time_tag_row': TimeTagRowTemplateComponent,
                'calendar_list': CalendarListTemplateComponent,
                'workgroup_workplaces': WorkgroupWorkplacesTemplateComponent,
                'workgroup_specialisations': WorkgroupSpecialisationsTemplateComponent,
                'agent_customer': AgentCustomerTemplateComponent,
                'toobar_action_template': ToolbarActionTemplateComponent,
                'resources_workgroup_operations_template': ResourcesWorkgroupOperationsTemplateComponent,
                'material_supplier_row': MaterialSupplierRowTemplateComponent,
                'login_block_files_row': LoginBlockFilesRowComponent,
                'analyzed_items_row': AnalyzedItemsRowComponent,
            }
        })
    ],
    providers: [],
    declarations: COMPONENTS,
    exports: [
        PromanListManagerModule,
    ]
})

export class FrontendListManagerModule {}
