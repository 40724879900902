import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { MaterialFormat } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-material-type-format',
    template: `
        <ng-container [ngSwitch]="item?.materialTypeFormat?.parameter?.type">
            <pm-txt *ngSwitchCase="'string'"
                    [value]="item.value"
                    [config]="_config"
                    [disabled]="disabled"
                    (onChange)="onChange.emit($event)"></pm-txt>

            <pm-number *ngSwitchCase="'number'"
                       [value]="item.value"
                       [config]="_config"
                       [disabled]="disabled"
                       (onChange)="onChange.emit($event)"></pm-number>

            <pro-select *ngSwitchCase="'dropdown'"
                    [value]="item.value"
                    [config]="_config"
                    [options]="options"
                    [disabled]="disabled"
                    (onChange)="onChange.emit($event)"></pro-select>
        </ng-container>
    `,
    styles: [`
        :host {
            flex: 1 1 auto;
        }
    `]
})

export class MaterialTypeFormatComponent implements OnInit {
    @Input() item: any;
    @Input() disabled: boolean;
    @Input() config: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDataLoad: EventEmitter<void> = new EventEmitter<void>();
    options: any[] = [];
    _config: any;

    constructor(
        private ParametersOptions: ParametersOptionsService,
    ) {

    }

    async ngOnInit() {

        if (!this.item.materialTypeFormat) {

            if (this.item.materialFormat) {
                const id = this.item.materialFormat && this.item.materialFormat.id || this.item.materialFormat;

                await this.ParametersOptions
                    .get({
                        entity: 'material_format',
                        entityParams: {
                            id,
                            join: ['materialTypeFormat', 'materialTypeFormat.parameter'],
                            sort: {position: 'asc'}
                        }
                    })
                    .then((response: MaterialFormat) => {
                        this.item.materialTypeFormat = response.materialTypeFormat;
                        this.onDataLoad.emit();
                    });

            } else if (this.item.materialFormat && this.item.materialFormat.materialTypeFormat) {
                this.item.materialTypeFormat = this.item.materialFormat.materialTypeFormat;

            }

        }

        const parameter = this.item.materialTypeFormat?.parameter;

        if (parameter) {
            if (this.item.materialTypeFormat && parameter && parameter.type === 'dropdown') {
                this.ParametersOptions
                    .search({
                        entity: 'parameter_dropdown_option',
                        entityParams: {'parameter.id': this.item.materialTypeFormat.parameter.id, 'translate': true}
                    })
                    .then((response: any) => this.options = response);
            }

            this._config = Object.assign({
                label: parameter.name,
                key: 'name',
                disableSearch: true,
                parseNumber: parameter.type === 'number'
            }, this.config);

        }

    }

}
